import React from 'react'

const ProfessionTxt = ({title,opacity, onClick, subtitle, btSize, background,htext, ptext,TLeft, TTop}) => {
  return (
    <div    className={` `} style={{
      left: `${TLeft}`, 
      top:  `${TTop}`,
      opacity: opacity,
    }} onClick={onClick}> 
        <div   className={`rounded-full  mainTtext ${btSize} bg-[${background}] `}   >
            <h1 style={{
              lineHeight: "28px",
              fontSize: `${htext}px`
            }} className={` mb-2 `}>{title}</h1>
            <p style={{
              fontSize:`${ptext}px`
            }} className={` `}>{subtitle}</p>
        </div>
    </div>
  )
}

export default ProfessionTxt