import React from 'react'
import HeroSection from "../components/HeroSection";
import TypesSection from "../components/TypesSection";
import Social from "../components/Social";
import ImageSection from "../components/ImageSection";
import image1 from "../images/9.png";
import image2 from "../images/mac mockup.png";
import Footer from "../components/Footer";
import StickyNavbar from "../components/StickyNavbar";
function Blockchain() {
  return (
      <div id='hero'>
 <HeroSection
        heading1="SHADE"
        heading2="OF"
        heading3="Success"
        label1="BLOCKCHAIN"
        label2="WHY"
        label3="APROACHES"
        label4="FUTURE"
        link1="#BLOCKCHAIN"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="BLOCKCHAIN">
      <Social
        heading1="Blockchain"
        heading2="Services"
        heading3=""
        heading="Blockchain Services"
        para1="In the digital era, blockchain technology has emerged as a transformative force, offering innovative solutions that go beyond cryptocurrencies. At 4Caps, our blockchain services are designed to not only leverage the decentralized power of blockchain but also provide strategic solutions that redefine how businesses operate in a secure and transparent environment.

      "
        />
      </div>
      <div id="work">
      <ImageSection
        heading1="Why Blockchain Services Matter
        "
        heading2="Your Vision Brought to Life"
        paragraph1="Blockchain is more than just a buzzword; it's a paradigm shift in how data is stored, verified, and transacted. Its decentralized nature ensures security, immutability, and transparency, making it a game-changer across various industries. Whether it's supply chain management, smart contracts, or decentralized finance, blockchain services unlock a new realm of possibilities for businesses.

      "
        paragraph2="Our blockchain services go beyond technology implementation; they're about realizing your vision of a more secure, transparent, and efficient business environment. We take pride in delivering solutions that not only align with your objectives but also position you as a pioneer in your industry.
        .
      "
        imageUrl={image1}
        />
      </div>
      <div id="projects">
      <TypesSection
        heading1="Creative Approach to Blockchain Services
        "
        heading2="Customized Solutions"
        heading3="Blockchain Integration"
        heading4="Smart Contract Development"
        heading5="Tokenization"
        heading6="Security Measures"
        heading7="Education and Training:"
        p1="Our approach to blockchain services is founded on a deep understanding of your business processes, objectives, and the potential for blockchain integration. Here's how we achieve this.


      "
        p2=" One size doesn't fit all in blockchain. We collaborate closely with you to identify specific pain points and opportunities where blockchain can add value. Whether it's enhancing security, improving traceability, or automating processes, our solutions are tailored to your unique needs.
      "
        p3="From private blockchains for internal processes to public blockchains for decentralized applications, we guide you in choosing the right type of blockchain for your business. Our integration strategies ensure seamless incorporation into your existing systems.
        "
        p4=" Smart contracts are the backbone of many blockchain applications. We specialize in developing smart contracts that automate and enforce agreements, enhancing efficiency and reducing the risk of human error.


      "
        p5=" Whether for fundraising or creating a digital representation of assets, we guide you through the tokenization process. From initial coin offerings (ICOs) to non-fungible tokens (NFTs), we help you navigate and implement token-based solutions.
        "
        p6="Blockchain is renowned for its security, but proper implementation is crucial. We implement robust security measures to protect against vulnerabilities and ensure the integrity of your data on the blockchain.
      "
        p7=" Understanding blockchain is paramount for successful implementation. We provide education and training to your team, ensuring everyone is equipped to utilize and maintain blockchain solutions effectively.

      "
        />
        </div>
         <StickyNavbar
        menu1="Blockchain"
        menu2="Why"
        menu3="Future"
        menu4="Find Us"
        menu5="Aproaches"
        link1="#BLOCKCHAIN"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="team">
      <ImageSection
        heading1="Ongoing Support and Optimization
      "
        heading2="Let 4Caps Transform Your Business with Blockchain!

      "
        paragraph1="Our commitment extends beyond the initial implementation. We offer ongoing support, maintenance, and optimization to ensure your blockchain solutions evolve with technological advancements and your business needs.

      "
        paragraph2="Blockchain is not just a technology; it's a catalyst for innovation. Partner with 4Caps to harness the full potential of blockchain. Contact us today to explore how we can create customized blockchain solutions that not only meet your current needs but also position your business for a successful and sustainable digital future

      "
        imageUrl={image2}
        />
        </div>
      <Footer />
    </div>
  )
}

export default Blockchain