import React from 'react'
import MainNavbar from './MainNavbar'
import { Link } from 'react-router-dom'
const HeroSection = (props) => {
  return (
    <div id="word" className='h-full relative w-full'>
      <div className='mainHero flex relative h-[100vh] md:h-[180vh] w-full '>
        <MainNavbar heading1={props.label1} heading3={props.label3} heading2={props.label2} heading4={props.label4} heading5="FIND US" link1={props.link1} link2={props.link2} link3={props.link3} link4={props.link4} link5={props.link5} />
        <Link to="/" ><div className="logo">
          {/* <img height={150} src='../../images/logo_blanc.png'/> */}
        </div></Link>

        <h1 className=' bannerHeading  w-full md:max-w-fit absolute top-[35%] md:top-[25%] left-[10%] text-white md:text-[8vw] '>{props.heading1} <br /> &nbsp; {props.heading2} <span>{props.heading3}</span>
        </h1>
       
      </div>
    </div>
  )
}
export default HeroSection