import React from "react";
import HeroSection from "../components/HeroSection";
import TypesSection from "../components/TypesSection";
import Social from "../components/Social";
import ImageSection from "../components/ImageSection";
import image1 from "../images/3.png";
import image2 from "../images/mac mockup.png";
import Footer from "../components/Footer";
import StickyNavbar from "../components/StickyNavbar";


function WebServices() {
  return (
    <div id="hero">
      <HeroSection
        heading1="SHADE"
        heading2="OF"
        heading3="Success"
        label1="WEB SERVICES"
        label2="WHY"
        label3="APROACHES"
        label4="FUTURE"
        link1="#WEB"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="WEB">
      <Social
        heading1="WEBSITE"
        heading2="Services"
        heading3=""
        heading="Creative Web Development Services"
        para1="In the digital age, a strong online presence is non-compromiseable for businesses seeking to grow and remain competitive. A well-designed website is the cornerstone of this online presence, and that's where our creative web services come into play. At 4Caps, we're dedicated to creating dynamic, high-performance websites that not only capture the essence of your brand but also engage and convert visitors into your loyal customers."
        />
      </div>
      <div id="work">
      <ImageSection
        heading1="Why Web Development Matters"
        heading2="Your Vision is Realized"
        paragraph1="Your website is often the first point of contact between your
        business and your target audience. It's your digital storefront, and
        it should make a compelling first impression. Furthermore, it serves
        as the central hub for all your online marketing efforts, from SEO
        and content marketing to social media campaigns and email marketing.
        In other words, a well-designed website is the linchpin that ties
      your entire digital strategy together"
        paragraph2="Our web development services aren't just about creating websites;
      they're about turning your vision into reality. We take pride in
      delivering websites that reflect your brand's identity, engage your
      audience, and drive measurable results."
        imageUrl={image1}
        />
      </div>
     
     <StickyNavbar
        menu1="Web Services"
        menu2="Why"
        menu3="Future"
        menu4="Find Us"
        menu5="Aproaches"
        link1="#WEB"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
       <div id="projects">
      <TypesSection
        heading1="Creative Approach to Web Development"
        heading2="Customized Solutions"
        heading3="Responsive Design"
        heading4="Cutting-Edge Technology"
        heading5="SEO Optimization"
        heading6="User-Centric Experience"
        heading7="Scalability"
        heading8="Robust Security"
        p1="Our approach to web development is rooted in a deep understanding of
        your unique business goals and a commitment to delivering a seamless
        user experience. Here's how we achieve this:"
        p2=" We don't believe in one-size-fits-all solutions. Every business is
        unique, and your website should reflect that. We work closely with you
        to understand your specific needs, target audience, and branding to
        create a tailor-made website that stands out."
        p3="In an era where users access the internet from a variety of devices, a
        responsive website is a must. Our team ensures that your site looks
        and functions flawlessly on desktops, laptops, tablets, and
        smartphones, providing an optimal user experience across the board."
        p4="We stay up to date with the latest web development technologies and
        trends. Whether it's using the latest frameworks, incorporating
        interactive features, or ensuring fast loading times, we leverage
        technology to create a website that's both visually stunning and
        highly functional."
        p5=" We understand that a beautifully designed website is of little use if
        it can't be found. Our web development services are fully integrated
        with SEO best practices, ensuring that your site is search
        engine-friendly from the ground up."
        p6="Your website should not only look good but also be easy to navigate.
        We put the user experience at the forefront of our design, making sure
        that visitors can easily find the information they need and take
        desired actions."
        p7="We build websites that can grow with your business. Whether you're a
        startup or an established enterprise, our websites are designed with
        scalability in mind, allowing for easy updates and expansions as your
        business evolves."
        p8="Ensuring the security of your website and the data it holds is a top
        priority. We implement best-in-class security measures to protect your
        site from threats and vulnerabilities."
        />
      </div>
      <div id="team">
      <ImageSection
        heading1="Ongoing Support and Maintenance"
        heading2="Let 4caps build your Digital Future!"
        paragraph1="Our commitment doesn't end with the launch of your website. We offer ongoing support and maintenance to ensure that your site remains in peak condition. From updates and security patches to content management and performance optimization, we're here to keep your website running smoothly"
        paragraph2="Your website is more than just a digital presence; it's a strategic asset that can drive your business forward. Partner with 4Caps to leverage the power of web development. Contact us today to discuss how we can create a website that not only meets your current needs but also positions your business for a successful digital future."
        imageUrl={image2}
        />
        </div>
      <Footer />
      
    </div>
  );
}

export default WebServices;
