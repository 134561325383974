import React from 'react';
export const ServicesTitle = ({ SerTitle, onClick }) => {
  return (
    <div className={`absolute sector`}>
      <div className={`h-[22vh]  rounded-full w-[22vh] flex items-center p-2 justify-center border-2 border-[#930101]`}>
        <h1 onClick={onClick} className='h-full cursor-pointer w-full flex text-center items-center justify-center text-white bg-[#FF0126] rounded-full reds'>
          {SerTitle}
        </h1>
      </div>
    </div>
  );
};
export default ServicesTitle;
