import React from 'react'
import { Link } from 'react-router-dom';

const ProfileImgage = ({ image, ILeft, ITop, opacity, onClick, height, width, name, designation,linkdin,facebook,instagram}) => {
  return (
    <div className='  ' style={{
      left: `${ILeft}`,
      Top: `${ITop}`,
      opacity: opacity, // Apply opacity here
    }} onClick={onClick}>

      <div >
        <div
          className='rounded-full'
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: height,
            width: width,
          }}

        ><div className='slide-in' style={{
          height: height,
          width: width,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",

          paddingBottom: "20px",
        }} >
            <div className='text-center'>
              <p class="text-lg leading-normal font-bold mb-1">{name}</p>
              <p class="text-black-500 leading-relaxed font-light">{designation}</p>

              <Link class="hover:text-blue-700 p-1" aria-label="Facebook link" to={facebook}>

                <svg xmlns="http://www.w3.org/2000/svg" class="inline-block" width="1rem" height="1rem" viewBox="0 0 512 512">
                  <path fill="currentColor" d="M455.27,32H56.73A24.74,24.74,0,0,0,32,56.73V455.27A24.74,24.74,0,0,0,56.73,480H256V304H202.45V240H256V189c0-57.86,40.13-89.36,91.82-89.36,24.73,0,51.33,1.86,57.51,2.68v60.43H364.15c-28.12,0-33.48,13.3-33.48,32.9V240h67l-8.75,64H330.67V480h124.6A24.74,24.74,0,0,0,480,455.27V56.73A24.74,24.74,0,0,0,455.27,32Z"></path>
                </svg>
              </Link>
              <Link class="hover:text-blue-700 p-1" aria-label="Instagram link" to={instagram}>

                <svg xmlns="http://www.w3.org/2000/svg" class="inline-block" width="1rem" height="1rem" viewBox="0 0 512 512">
                  <path fill="currentColor" d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z"></path>
                  <path fill="currentColor" d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z"></path>
                  <path fill="currentColor" d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z"></path>
                </svg>
              </Link>
              <Link class="hover:text-blue-700 p-1" aria-label="Linkedin link" to={linkdin}>

                <svg xmlns="http://www.w3.org/2000/svg" class="inline-block" width="1rem" height="1rem" viewBox="0 0 512 512">
                  <path fill="currentColor" d="M444.17,32H70.28C49.85,32,32,46.7,32,66.89V441.61C32,461.91,49.85,480,70.28,480H444.06C464.6,480,480,461.79,480,441.61V66.89C480.12,46.7,464.6,32,444.17,32ZM170.87,405.43H106.69V205.88h64.18ZM141,175.54h-.46c-20.54,0-33.84-15.29-33.84-34.43,0-19.49,13.65-34.42,34.65-34.42s33.85,14.82,34.31,34.42C175.65,160.25,162.35,175.54,141,175.54ZM405.43,405.43H341.25V296.32c0-26.14-9.34-44-32.56-44-17.74,0-28.24,12-32.91,23.69-1.75,4.2-2.22,9.92-2.22,15.76V405.43H209.38V205.88h64.18v27.77c9.34-13.3,23.93-32.44,57.88-32.44,42.13,0,74,27.77,74,87.64Z"></path>
                </svg>
              </Link>

            </div>
          </div></div>


      </div>

    </div>
  )
}

export default ProfileImgage;