import React, { useState } from 'react';

const MainNavbar = (props) => {
  const [activeColor, setActiveColor] = useState('');

  const handleHover = (color) => {
    setActiveColor(color);
  };

  const handleMouseLeave = () => {
    setActiveColor('');
  };

  return (
    <div className='absolute mainTexttt   right-[6%] top-0 h-[250px] lg:h-[400px] w-[200px] lg:w-[300px] '>
      <ul className={` p-[40px] navigation h-full w-full text-xl items-start text-center justify-end text-white flex flex-col bg-[#f8de75] ${activeColor}`}>
      <a
          className='a2'
          href="#hero"
          onMouseEnter={() => handleHover('green')}
          onMouseLeave={handleMouseLeave}
        >
        {props.heading2}
        </a>

        <a
          className='a1'
          href={props.link1}
          onMouseEnter={() => handleHover('red')}
          onMouseLeave={handleMouseLeave}
        >
         {props.heading1}
        </a>
        <a
          className='a2'
          href="#work"
          onMouseEnter={() => handleHover('green')}
          onMouseLeave={handleMouseLeave}
        >
        {props.heading2}
        </a>
        <a
          className='a2'
          href="#projects"
          onMouseEnter={() => handleHover('blue')}
          onMouseLeave={handleMouseLeave}
        >
        {props.heading3}
        </a>
        <a
          className='a3'
          href="#team"
          onMouseEnter={() => handleHover('yellow')}
          onMouseLeave={handleMouseLeave}
        >
      {props.heading4}
        </a>
        <a
          className='a4'
          href="#findus"
          onMouseEnter={() => handleHover('blue')}
          onMouseLeave={handleMouseLeave}
        >
          {props.heading5}
        </a>
        <div className='text-[#D83F55] small-font'>PK/<span className='text-white small-font'>USA</span> </div>
      </ul>
      <div className='hidden md:block'>
        <h3 className='pt-5 text-white font-bold'>June 28th, <span className='font-light text-white'>2017 </span> </h3>

      </div>
    </div>
  );
};

export default MainNavbar;
