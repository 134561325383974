import React from "react";
import HeroSection from "../components/HeroSection";
import Social from "../components/Social";
import TypesSection from "../components/TypesSection";
import ImageSection from "../components/ImageSection";
import image1 from "../images/3.png";
import Footer from "../components/Footer";
import StickyNavbar from "../components/StickyNavbar";
function BrandingServices() {
  return (
    <div id="hero">
     <HeroSection
        heading1="SHADE"
        heading2="OF"
        heading3="Success"
        label1="BRANDING"
        label2="APROACHES"
        label3="WHY"
        label4="IMPACT"
        link1="#BRANDING"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="BRANDING">
      <Social
        heading1="BRANDING"
        heading2="Services"
        heading3=""
        heading="Branding Services: Crafting Your Digital Identity"
        para1="In the crowded digital landscape, a strong and distinctive brand is your most potent weapon. 4Caps understand the power of branding in shaping how your business is perceived. Our Branding Services are dedicated to helping you create a powerful and memorable brand identity that resonates with your target audience and sets you apart from the competition."
        />
      </div>
      <div id="work">
      <TypesSection
        heading1="Our Approach to Branding"
        heading2="Brand Strategy"
        heading3="Brand Identity"
        heading4="Messaging and Storytelling"
        heading5="Brand Guidelines"
        heading6="Digital Presence"
        p1="We take a comprehensive and strategic approach to branding, aiming to build a brand that not only looks appealing but also communicates your values, mission, and unique selling points. Here's how we achieve this:"
        p2=" We start by understanding your business, your industry, and your competitors. Our team conducts in-depth market research to identify opportunities and challenges. We then work with you to define your brand's core values, target audience, and unique selling proposition."
        p3="With a solid strategy in place, we move on to creating a brand identity that reflects your business's essence. This includes logo design, color schemes, typography, and visual elements that form the visual representation of your brand"
        p4="Your brand's story is a powerful tool in connecting with your audience. We help you craft compelling brand messaging that resonates with your target market. Whether it's your website copy, social media content, or marketing materials, we ensure your messaging is consistent and compelling."
        p5=" Consistency is key in branding. We create comprehensive brand guidelines that serve as a reference for your team and partners. These guidelines cover how your brand should be presented across various mediums, ensuring a unified and professional image."
        p6="In the digital age, your online presence is often the first point of contact with potential customers. We ensure that your brand's personality shines through on your website, social media profiles, and email communications.
        Content and Visual Assets: Our branding services also extend to creating compelling content and visual assets that reflect your brand. This includes videos, infographics, blog posts, and other content that engage your audience and reinforce your brand."
        />
        </div>
      <StickyNavbar
        menu1="Branding"
        menu2="Aproaches"
        menu3="Impact"
        menu4="Find Us"
        menu5="Why"
        link1="#BRANDING"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="projects">
      <ImageSection
        heading1="Why Branding Matters"
        heading2="Partner with 4Caps for Your Branding Needs"
        paragraph1="Your brand is more than just a logo or a tagline; it's the personality and reputation of your business. It's what your customers think of when they hear your name, and it influences their decision to engage with your products or services. In the digital world, where first impressions often happen online, effective branding is critical for success."
        paragraph2="Your brand is the essence of your business, and it deserves the utmost care and attention. Partner with 4Caps to unlock the full potential of your brand. Contact us today to explore how our branding services can help you create a powerful digital identity that drives success in the online world."
        imageUrl={image1}
        />
      </div>
      <div id="team">
      <TypesSection
        heading1="The Impact of Strong Branding"
        heading2="Credibility and Trust"
        heading3="Differentiation"
        heading4="Loyalty and Advocacy"
        
        p1="A strong brand can have a profound impact on your business:"
        p2="A well-defined brand instills trust and credibility in your audience. It signals professionalism and reliability, making it easier for customers to choose your products or services."
        p3="In a competitive landscape, a unique and memorable brand helps you stand out. It's the reason why customers choose your brand over others."
        p4="Strong branding creates an emotional connection with your customers. They're not just buying a product; they're buying into your story and values. This leads to greater loyalty and even advocacy, as satisfied customers become brand advocates.
        Consistency: Consistent branding across all touchpoints reinforces your message and builds recognition. When customers see your brand, they instantly know what to expect."
   
        />
        </div>
      <Footer />
    </div>
  );
}

export default BrandingServices;
