import React from 'react'
import HeroSection from "../components/HeroSection";
import TypesSection from "../components/TypesSection";
import Social from "../components/Social";
import ImageSection from "../components/ImageSection";
import image1 from "../images/3.png";
import image2 from "../images/mac mockup.png";
import Footer from "../components/Footer";
import StickyNavbar from "../components/StickyNavbar";

function SocialMedia() {
  return (
      <div id='hero'>
          <HeroSection
        heading1="SHADE"
        heading2="OF"
        heading3="Success"
        label1="SOCIAL MEDIA"
        label2="WHY"
        label3="APROACHES"
        label4="FUTURE"
        link1="#SOCIAL"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="SOCIAL">
      <Social
        heading1="Social"
        heading2="Media"
        heading3="services"
        heading="Social Media Services"
        para1="In the age of digital connectivity, a robust social media presence is imperative for businesses aiming to thrive and stay ahead. Crafting a compelling online identity begins with effective social media strategies, and that's where our creative social media services come into play. At 4Caps, we are committed to developing dynamic, high-impact social media campaigns that not only encapsulate your brand's essence but also captivate and convert audiences into loyal followers.
        "
        />
      </div>
      <div id="work">
      <ImageSection
        heading1="Why Social Media Matters"
        heading2="Your Vision Brought to Life"
        paragraph1="Social media serves as the primary touchpoint between your business and your target audience. It's your virtual storefront, responsible for making that crucial first impression. Moreover, it functions as the nerve center for all your online marketing endeavors, from SEO and content marketing to engaging social media campaigns and targeted email marketing. A well-executed social media strategy is the linchpin that ties together your entire digital presence."
        paragraph2="Our social media services go beyond managing profiles; they're about bringing your vision to life. We take pride in curating social media campaigns that not only reflect your brand's identity but also foster engagement, building a community around your business."
        imageUrl={image1}
        />
        </div>
       <StickyNavbar
        menu1="Social Media"
        menu2="Why"
        menu3="Future"
        menu4="Find Us"
        menu5="Aproaches"
        link1="#SOCIAL"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="projects">
      <TypesSection
        heading1="Creative Approach to Social Media Management"
        heading2="Customized Strategies"
        heading3="Engaging Content"
        heading4="Platform Optimization"
        heading5="Trend Integration"
        heading6="Analytics and Optimization"
      
        p1="Our approach to social media services is grounded in a profound understanding of your unique business objectives, coupled with a commitment to delivering a seamless user experience. Here's how we achieve this:"
        p2=" We don't believe in a one-size-fits-all approach. Every business is distinctive, and your social media strategy should mirror that. We collaborate closely with you to comprehend your specific needs, target audience, and branding, crafting tailor-made social media campaigns that stand out."
        p3="In a landscape flooded with information, content is king. Our team ensures that your social media content is not only visually compelling but also resonates with your audience, driving meaningful engagement and interactions."
        p4="Different platforms demand different strategies. We tailor our approach to suit each platform, maximizing the impact of your presence on popular social media channels.
        "
        p5=" Staying ahead of trends is crucial in the dynamic realm of social media. We integrate the latest trends and technologies to keep your social media presence fresh and appealing."
        p6="We employ data-driven insights to continually refine and optimize your social media strategy, ensuring that it evolves alongside your business objectives.
        "
        
        />
      </div>
      <div id="team">
      <ImageSection
        heading1="Ongoing Support and Optimization"
        heading2="Let 4Caps Elevate Your Social Media Presence!
        "
        paragraph1="Our commitment extends beyond the launch of your social media campaigns. We offer ongoing support and optimization to ensure that your social media presence remains vibrant and impactful. From content updates and trend integration to performance analytics and strategy refinements, we're here to keep your social media strategy running smoothly.
        "
        paragraph2="Your social media presence is not just about being online; it's a strategic asset that can propel your business forward. Partner with 4Caps to harness the potential of social media. Contact us today to explore how we can create a social media strategy that not only meets your current needs but also positions your business for a successful digital future.

        "
        imageUrl={image2}
        />
        </div>
      <Footer />
    </div>
  )
}

export default SocialMedia