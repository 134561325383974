import React, { useState } from 'react';
import ServicesText from './services/ServicesText';
import ServicesTitle from './services/ServicesTitle';
import { Link } from 'react-router-dom';




const Services = () => {
  const ServicesData = [
    {
      id: 1,
      title: "Social Media",
      paragraph: " we provide strategic management and engaging content creation to boost your social media presence and connect with your audience effectively",
      MainTitle: "Social Media",
      service: "/socialmedia"

    },
    {
      id: 2,
      title: "Artificial Intelegence",
      paragraph: "Our Telegram Services cater to the growing popularity of this messaging platform, offering unique solutions to connect with your audience and deliver your message seamlessly.",
      MainTitle: "Artificial Intelegence",
      service: "/artificialintelegence"
    },
    {
      id: 3,
      title: "Influencer Marketing",
      paragraph: "Influencer Marketing is another forte, where we collaborate with influencers to promote your brand and reach a wider audience authentically.",
      MainTitle: "Influencer Marketing",
      service: "/influencermarketing"
    },
    {
      id: 4,
      title: "Blockchain", // Fix the typo here: "title" instead of "title"
      paragraph: "Explore the future of the web with cutting-edge Web 3.0 development, leveraging blockchain for a modern digital experience.",
      MainTitle: "Blockchain",
      service: "/blockchain"
    },
    {
      id: 5,
      title: "Digital Marketing",
      paragraph: "Boost engagement and conversions through strategic email marketing campaigns that connect you directly with your audience.",
      MainTitle: "Digital Marketing",
      service: "/digitalmarketing"
    },
    {
      id: 6,
      title: "Branding Services",
      paragraph: "A strong brand is more than just a logo or a catchy name. It's the total sum of all the experiences that customers have with a brand, from the way they see it advertised to the way they interact.",
      MainTitle: "Branding Services",
      service: "/BrandingServices"
    },
    {
      id: 7,
      title: "Website Services",
      paragraph: "Build a stunning online presence with our custom website development, tailored to your business needs and designed for maximum impact.",
      MainTitle: "Website Services",
      service: "/webservice"
    },
    // Add other service data here
  ];

  const [selectedService, setSelectedService] = useState(null);

  const handleServiceClick = (id) => {
    setSelectedService(id);
  };

  return (
    <div className='h-auto md: backgrounds w-full py-20'>
      <h1 id="work" className='text-4xl expertiseee md:text-white text-white w-full text-center background pb-5 pt-5'>Our Expertise</h1>
      <div className='flex relative md:flex-row flex-col items-center justify-center mt-16'>
        <div className='h-auto height-texts w-full md:w-[450px] md:h-[450px] py-5  md:py-10 md:pt-[6%] md:rounded-full  overflow-hidden flex flex-col items-center justify-start reds'>
          <h1 className='pb-4  md:pt-4  border-b-2 w-[70%] text-left text-3xl text-white border-white lovelo-black'>Services</h1>
          {selectedService !== null ? (
            <ServicesText
              ServicesTitlee={ServicesData[selectedService - 1].title}
              ServicesParagraph={ServicesData[selectedService - 1].paragraph}
            />
          ) : <ServicesText
            ServicesTitlee={ServicesData[1].title}
            ServicesParagraph={ServicesData[1].paragraph}

          />}
          <Link to={selectedService !== null ? ServicesData[selectedService - 1].service : '/'}>
          <button type="button" class="text-yellow-400 hover:text-white border border-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-yellow-300 dark:text-yellow-300 dark:hover:text-white dark:hover:bg-yellow-400 dark:focus:ring-yellow-900">Read More</button>
          </Link>

        </div>
        <div className='md:w-[450px]    md:h-[450px]  w-[250px] h-[250px] md:mt-[0] mt-12  flex items-center justify-evenly mainThis relative rounded-full md:ml-[-5%]  border-4 border-gray-300 '>
          {ServicesData.map(service => (
            <ServicesTitle
              key={service.id}
              SerTitle={service.MainTitle}
              onClick={() => handleServiceClick(service.id)}
            />
          ))}
        </div>

      </div>

    </div>
  );
};

export default Services;
