import React from "react";

const CreativeWebDevelopment = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ textAlign: "center", width: "75%", paddingBottom:"20px", paddingTop:"20px" }}>
        <h1
          className="sfont"
          style={{ fontSize: "35px", paddingBottom: "20px" }}
        >
          {props.heading1}
        </h1>
        <p className="lato">
        {props.p1}
        </p>

        <h2 className="sfont">{props.heading2}</h2>
        <p className="lato">
         {props.p2}
        </p>

        <h2 className="sfont">{props.heading3}</h2>
        <p className="lato">
          {props.p3}
        </p>

        <h2 className="sfont">{props.heading4}</h2>
        <p className="lato">
          {props.p4}
        </p>

        <h2 className="sfont">{props.heading5}</h2>
        <p className="lato">
         {props.p5}
        </p>

        <h2 className="sfont">{props.heading6}</h2>
        <p className="lato">
          {props.p6}
        </p>

        <h2 className="sfont">{props.heading7}</h2>
        <p className="lato">
          {props.p7}
        </p>

        <h2 className="sfont">{props.heading8}</h2>
        <p className="lato">
          {props.p8}
        </p>
      </div>
    </div>
  );
};

export default CreativeWebDevelopment;
