import React from "react";
import HeroSection from "../components/HeroSection";
import TypesSection from "../components/TypesSection";
import Social from "../components/Social";
import ImageSection from "../components/ImageSection";
import image1 from "../images/5.png";
import image2 from "../images/mac mockup.png";
import Footer from "../components/Footer";
import StickyNavbar from "../components/StickyNavbar";
function DigitalMarketing() {
  return (
    <div id="hero">
      <HeroSection
        heading1="SHADE"
        heading2="OF"
        heading3="Success"
        label1="DIGITAL MARKETING"
        label2="WHY"
        label3="APROACHES"
        label4="FUTURE"
        link1="#DIGITAL"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="DIGITAL">
      <Social
        heading1="Digital"
        heading2="Marketing"
        heading3="Services"
        heading="Digital Marketing"
        para1="In the dynamic landscape of online business, digital marketing is the cornerstone for achieving visibility, engagement, and growth. At 4Caps, our digital marketing services are meticulously designed to not only elevate your brand's online presence but also to strategically connect with your target audience, driving measurable results.

        "
        />
      </div>
      <div id="work">
      <ImageSection
        heading1="Why Digital Marketing Services Matter
        "
        heading2="Your Vision Brought to Life"
        paragraph1="Digital marketing is the heartbeat of modern business strategies. It's more than just promoting products or services; it's about crafting a compelling narrative that resonates with your audience across various digital channels. From search engine optimization (SEO) to content marketing and social media campaigns, our services are tailored to navigate the intricacies of the digital realm.
        "
        paragraph2="Our digital marketing services are more than just campaigns; they're about realizing your vision of a strong, influential online presence. We take pride in delivering strategies that not only elevate your brand but also contribute to the achievement of measurable and meaningful results.
        "
        imageUrl={image1}
        />
        </div>
      <StickyNavbar
        menu1="D Marketing"
        menu2="Why"
        menu3="Future"
        menu4="Find Us"
        menu5="Aproaches"
        link1="#DIGITAL"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="projects">
      <TypesSection
        heading1="Creative Approach to Digital Marketing
        "
        heading2="Customized Solutions"
        heading3="Multi-Channel Campaigns"
        heading4="Content Creation and Optimization"
        heading5="Data-Driven Analytics"
        heading6="Conversion Optimization"
        p1="Our approach to digital marketing is founded on understanding your business objectives, target audience, and the digital landscape in which you operate. Here's how we achieve this:

        "
        p2=" We reject the one-size-fits-all mentality. Every business is unique, and we collaborate closely with you to understand your industry, competition, and distinctive selling propositions. Our strategies are tailor-made to align with your specific goals.
        "
        p3="Digital marketing is multi-faceted. We craft campaigns that span various channels, from search engines and social media to email and content marketing. This comprehensive approach ensures that your brand is consistently visible and engaging across the digital spectrum.
        "
        p4="Content is the backbone of digital marketing. Our team specializes in creating not only visually appealing content but also strategically optimized content that resonates with both your audience and search engines, boosting your online visibility.

        "
        p5=" Digital marketing thrives on data. We utilize analytics to measure the performance of campaigns, identify areas for improvement, and refine strategies. This data-driven approach ensures that every digital marketing effort contributes to your overarching business objectives."
        p6="Driving traffic is essential, but conversions are the ultimate goal. We focus on optimizing user journeys, enhancing user experience, and implementing strategies to convert visitors into leads or customers.
        "
        />
      </div>
      <div id="team">
      <ImageSection
        heading1="Ongoing Support and Optimization
        "
        heading2="Let 4Caps Elevate Your Digital Presence!
        "
        paragraph1="Our commitment extends beyond the launch of campaigns. We offer ongoing support, monitoring performance, and optimizing strategies to keep your digital marketing efforts agile and effective in a rapidly evolving digital landscape.
        "
        paragraph2="Digital marketing is not just about online visibility; it's a strategic asset that can drive your business forward. Partner with 4Caps to leverage the power of digital marketing. Contact us today to discuss how we can create customized digital marketing strategies that not only meet your current needs but also position your business for a successful and enduring digital future.

        "
        imageUrl={image2}
        />
        </div>
      <Footer />
    </div>
  );
}

export default DigitalMarketing;
