import React from 'react';
import Video from "../videos/s.mp4"
const Social = (props) => {
  return (
    <div className='w-full  flex items-center justify-center mt-[-10vh] md:mt-[-40vh] '>
      <div style={{
        width: "fit-content"
      }} className='  flex z-10 items-center justify-center flex-col'>

        <div className='background flex md:flex-row flex-col w-full items-center justify-center'>
          <div className=' px-10   bg-gray-100 w-[98%]  md:w-1/2 flex flex-col items-start justify-center'>
            <video className='z-20 mt-[-50%]' controls muted loop autoPlay height={500} width={500}>
              <source src={Video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <h6 className='text-sm'>DISCOVER OUR SNOWFALL</h6>
            <h1 className='font-bold text-2xl'>{props.heading}</h1>
            <p className='text-left mb-10 mt-[25%] lato'>
              {props.para1}
            </p>
          </div>
          <div className=' flex items-center justify-center w-full h-[40vh] md:w-[30%] md:h-[70vh] bg-[#930101] '>
            <h1 className='flex sfont flex-col text-white text-5xl  '>{props.heading1} <span className='text-3xl italicrock'>{props.heading2}</span> <span className='italicrock text-[90px]'>{props.heading3}</span></h1>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Social;
