import React from "react";
import HeroSection from "../components/HeroSection";
import TypesSection from "../components/TypesSection";
import Social from "../components/Social";
import ImageSection from "../components/ImageSection";
import image1 from "../images/8.png";
import image2 from "../images/1.png";
import Footer from "../components/Footer";
import StickyNavbar from "../components/StickyNavbar";
function InfluencerMarketing() {
  return (
    <div id="hero">
     <HeroSection
        heading1="SHADE"
        heading2="OF"
        heading3="Success"
        label1="I MARKETING"
        label2="WHY"
        label3="APROACHES"
        label4="FUTURE"
        link1="#INFLUENCER"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="INFLUENCER">
      <Social
        heading1="Influencer"
        heading2="Marketing"
        heading3="Services"
        heading="Influencer marketing"
        para1="In the ever-evolving landscape of digital marketing, influencer marketing has emerged as a powerhouse strategy for businesses aiming to connect with their target audience authentically. At 4Caps, our influencer marketing services are meticulously crafted to not only elevate your brand's visibility but also foster genuine connections that drive measurable results.

      "
        />
      </div>
      <div id="work">
      <ImageSection
        heading1="Why Influencer Marketing Matters"
        heading2="Your Vision Brought to Life"
        paragraph1="Influencers have become key players in shaping consumer opinions and purchasing decisions. Leveraging the trust and engagement influencers have with their audiences allows businesses to create impactful campaigns, reaching potential customers in a more authentic and relatable way. Influencer marketing is not just a trend; it's a strategic approach that humanizes your brand and builds credibility.
      "
        paragraph2="Our influencer marketing services go beyond transactional partnerships; they're about bringing your brand's story to life through authentic voices. We take pride in delivering campaigns that not only enhance your brand's visibility but also forge lasting connections with your audience.
      "
        imageUrl={image1}
        />
        </div>
     <StickyNavbar
        menu1="I Marketing"
        menu2="Why"
        menu3="Future"
        menu4="Find Us"
        menu5="Aproaches"
        link1="#INFLUENCER"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="projects">
      <TypesSection
        heading1="Creative Approach to Influencer Marketing"
        heading2="Customized Solutions"
        heading3="Customized Campaigns"
        heading4="Content Collaboration"
        heading5="Cross-Platform Integration"
        heading6="Performance Analytics"
        p1="Our approach to influencer marketing revolves around understanding your brand, target audience, and overarching goals. Here's how we achieve this:

      "
        p2=" We believe in the power of tailored solutions. Every brand is unique, and we collaborate closely with you to identify influencers whose values align with your brand. This ensures that each campaign feels authentic and resonates with the intended audience.
      "
        p3="We employ a meticulous process to select influencers based on more than just follower count. We consider relevance, engagement, and the alignment of the influencer's content with your brand message. This ensures that partnerships are not just about reach but also about building meaningful connections."
        p4=" Influencer marketing is not just about visibility; it's about storytelling. We work hand-in-hand with influencers to create compelling, authentic content that seamlessly integrates your brand message into their narrative, resonating with their audience.

      "
        p5=" We understand that each social media platform has its unique dynamics. Our influencer marketing strategies encompass a variety of platforms, ensuring your brand reaches its target audience across different channels"
        p6="Data is at the core of our approach. We utilize performance analytics to measure the effectiveness of influencer campaigns, allowing us to refine strategies, optimize engagement, and ensure a strong return on investment.
      "
        />
      </div>
      <div id="team">
      <ImageSection
        heading1="Ongoing Support and Optimization
      "
        heading2="Let 4Caps Amplify Your Brand with Influencers!
      "
        paragraph1="Our commitment extends beyond campaign launches. We offer ongoing support and optimization, continuously refining influencer strategies based on evolving trends, audience preferences, and your business objectives.
      "
        paragraph2="Influencer marketing is not just about endorsements; it's about building trust and loyalty. Partner with 4Caps to harness the full potential of influencer marketing. Contact us today to explore how we can create customized influencer campaigns that not only meet your current needs but also position your brand for a successful and enduring digital future.
      "
        imageUrl={image2}
        />
        </div>
      <Footer />
    </div>
  );
}

export default InfluencerMarketing;
