import React from 'react'

const ServicesText = ({ ServicesTitlee, ServicesParagraph, }) => {
  return (
    <div className='w-full flex flex-col items-center justify-start'>
      <h1 className='py-2 md:py-4 mt-3 border-b-2 w-[70%] text-left text-2xl text-white border-white '>{ServicesTitlee}</h1>
      <p className='w-[70%] text-white pt-4 lato'>{ServicesParagraph}</p>


    </div>
  )
}

export default ServicesText