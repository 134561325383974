
import React, { useState, useEffect } from 'react';
import './Carousel.css'; // Import your stylesheet
import image1 from "../images/neopakistan.png";
import image2 from "../images/greenster.jpg";
import image3 from "../images/WallpaperDog-10794358.jpg";
import image4 from "../images/IMG_9700.jpg";

const Carousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        // Auto-change slide every 2 seconds
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    const images = [
        {
            url: image1,
            heading: 'Neo Pakistan',
            text: 'Experience the joy of serving delicious Pakistani cusine',
        },
        {
            url: image2,
            heading: 'Greenster',
            text: 'Innovation at Global Hydrogen Energy Corp',
        },
        {
            url: image3,
            heading: '4CAPS',
            text: 'Shade of Success',
        },
        {
            url: image4,
            heading: '4CAPS DIGITAL',
            text: 'Discover Our Snowfall',
        },
    ];

    const containerStyle = {
        height: window.innerWidth < 768 ? '40vh' : '100vh', // Adjust height based on window width
    };

    const imageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    };

    return (
        <>
            <h1 className='background text-4xl expertiseee md:text-white text-white w-full text-center pb-5 pt-5 '>Our Projects</h1>
            <div id='projects' className="carousel-container" style={containerStyle}>
                <h1>hi</h1>
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`carousel-item ${index === currentSlide ? 'active' : ''}`}
                    >
                        <img
                            src={image.url}
                            alt={`Slide ${index + 1}`}
                            style={imageStyle}
                        />
                        <div className="carousel-content">
                            <h2 className='bannerHeading font'>{image.heading}</h2>
                            <p>{image.text}</p>
                        </div>
                    </div>

                ))}
            </div>
        </>
    );
};

export default Carousel;
