import React, { useState, useEffect } from 'react';


const StickyNavbar = (props) => {
  const [activeColor, setActiveColor] = useState('');
  const [showNavbar, setShowNavbar] = useState(true);

  const handleHover = (color) => {
    setActiveColor(color);
  };

  const handleMouseLeave = () => {
    setActiveColor('');
  };

  const checkScrollbarHeight = () => {
    if (window.scrollY > window.innerHeight * 0.8) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollbarHeight);
    checkScrollbarHeight();

    return () => {
      window.removeEventListener('scroll', checkScrollbarHeight);
    };
  }, []);

  return (
    <div className={`stickyNavbar z-20 hidden md:block  fixed right-[10%] top-0 ${showNavbar ? ' show' : 'hide'}`}>
      <ul className={`navigation p-5 w-[200px] h-full text-xl items-start text-center justify-end text-white flex flex-col bg-[#f8de75] ${activeColor}`}>
        <a
          className='a1'
          href={props.link1}
          onMouseEnter={() => handleHover('red')}
          onMouseLeave={handleMouseLeave}
        >
          {props.menu1}
        </a>
        <a
          className='a2'
          href={props.link2}
          onMouseEnter={() => handleHover('green')}
          onMouseLeave={handleMouseLeave}
        >
          {props.menu2}
        </a>
        <a
          className='a2'
          href={props.link3}
          onMouseEnter={() => handleHover('green')}
          onMouseLeave={handleMouseLeave}
        >
          {props.menu5}
        </a>
        <a
          className='a3'
          href={props.link4}
          onMouseEnter={() => handleHover('yellow')}
          onMouseLeave={handleMouseLeave}
        >
          {props.menu3}
        </a>
        <a
          className='a4'
          href={props.link5}
          onMouseEnter={() => handleHover('blue')}
          onMouseLeave={handleMouseLeave}
        >
          {props.menu4}
        </a>
        <div className='text-[#D83F55] small-font'>
          PK /<span className='text-white small-font'>USA</span>
        </div>
      </ul>
    </div>
  );
};

export default StickyNavbar;
