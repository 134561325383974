import React from "react";
import HeroSection from "../components/HeroSection";
import TypesSection from "../components/TypesSection";
import Social from "../components/Social";
import ImageSection from "../components/ImageSection";
import image1 from "../images/6.png";
import image2 from "../images/mac mockup.png";
import Footer from "../components/Footer";
import StickyNavbar from "../components/StickyNavbar";
function ArtificialIntelegence() {
  return (
    <div id="hero">
      <HeroSection
        heading1="SHADE"
        heading2="OF"
        heading3="Success"
        label1="AI"
        label2="WHY"
        label3="APROACHES"
        label4="FUTURE"
        link1="#AI"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
        
      />
    <div id="AI">
      <Social
        heading1="Artificial"
        heading2="Intelegence"
        heading3="Services"
        heading="Advanced Artificial Intelegence"
        para1="In the era of digital transformation, harnessing the capabilities of Artificial Intelligence (AI) is paramount for businesses aiming to stay ahead. At 4Caps, our AI services are designed to push the boundaries, offering innovative solutions that not only align with your business goals but also redefine your digital landscape.
        "
        />
        </div>
      <div id="work">
        <ImageSection
          heading1="Why AI Services Matter"
          heading2="Your Vision Brought to Life"
          paragraph1="AI has become a linchpin in the rapidly evolving digital landscape, revolutionizing the way businesses operate. From automating routine tasks to providing predictive analytics, AI empowers businesses to make informed decisions and enhance operational efficiency. It's not just a technological tool; it's a strategic asset that can transform the way you approach challenges and opportunities."
          paragraph2="Our AI services are not just about implementing algorithms; they're about bringing your strategic vision to life. We take pride in delivering AI solutions that not only streamline your operations but also contribute to achieving your broader business objectives."
          imageUrl={image1}
        />
      </div>
      <div id="projects">
        <TypesSection
          heading1="Creative Approach to AI Services"
          heading2="Customized Solutions"
          heading3="Cutting-Edge Technology"
          heading4="User-Centric Experience"
          heading5="Scalability"
          heading6="Security"
          p1="Our approach to AI services is built on a foundation of understanding your unique business objectives and a commitment to delivering seamless, intelligent solutions. Here's a breakdown of our approach:
        "
          p2=" We believe in tailoring AI solutions to your specific needs. Every business is distinct, and our team works closely with you to understand your industry, target audience, and unique challenges. This ensures that our AI solutions are not just off-the-shelf, but finely tuned to meet your precise requirements."
          p3="Staying at the forefront of AI technologies and trends is our commitment. Whether it's incorporating the latest machine learning algorithms, natural language processing, or computer vision, we leverage cutting-edge technology to deliver AI solutions that are not only powerful but also future-proof."
          p4="Just as in web development, user experience is paramount. Our AI solutions are designed with end-users in mind, ensuring seamless integration into your business processes and providing a user-friendly experience.
        "
          p5=" AI solutions should grow with your business. Whether you're a startup or an established enterprise, our AI services are designed with scalability in mind, allowing for easy integration into existing systems and accommodating future expansions."
          p6="Security is a non-negotiable aspect of AI implementation. We implement robust security measures to safeguard your data and ensure that your AI systems are resilient against potential threats."
        />
      </div>
      <StickyNavbar
        menu1="AI"
        menu2="Why"
        menu3="Future"
        menu4="Find Us"
        menu5="Aproaches"
        link1="#AI"
        link2="#work"
        link3="#projects"
        link4="#team"
        link5="#findus"
      />
      <div id="team">
        <ImageSection
          heading1="Ongoing Support and Optimization
        "
          heading2="Let 4Caps Drive Your AI Future!"
          paragraph1="Our commitment doesn't end with the launch of your website. We offer ongoing support and maintenance to ensure that your site remains in peak condition. From updates and security patches to content management and performance optimization, we're here to keep your website running smoothly"
          paragraph2="AI is more than a technological investment; it's a key driver of your business's future success. Partner with 4Caps to leverage the power of AI. Contact us today to discuss how we can create customized AI solutions that not only meet your current needs but also position your business for a successful digital future.
        "
          imageUrl={image2}
        />
      </div>
      <Footer />
    </div>
  );
}

export default ArtificialIntelegence;
