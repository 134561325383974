import React, { useState } from 'react';
import ProfileImage from './socialcomponents/ProfileImgage';
import ProfessionTxt from './socialcomponents/ProfessionTxt';
import Kelvin from '../images/Kelvin.png';
import Shahzaib from '../images/Shahzaib.png';
import Umar from '../images/Umar.png';
import Razziq from '../images/Razaq.png';
import greenster from '../images/gree.png';

import neo from '../images/neo.png';
import Moeed from '../images/Moeed.png';
import waqas from '../images/waqas.png';
import getinjury from '../images/get3.png';
import fourcaps from '../images/4c.png';
import Saqeef from '../images/Saqeef.png';



const Team = ({ height, opacity, onClick, width, image, heighttext, widthText, background, htext, }) => {
  const [selectedId, setSelectedId] = useState(null);

  const handleProfessionTxtClick = (id) => {
    if (selectedId === id) {
      setSelectedId(null); // Reset to null if the same component is clicked again
    } else {
      setSelectedId(id);
    }
  };

  return (
    <div className='background'>
      <div className='py-[60px] w-[80%] m-auto'>
        <div className='text-white'>
          <h1 id="team" className='text-5xl sfont text-left '>
            "PLEASURE IN THE JOB <br />
            PUTS PERFECTION <br />
            IN THE WORK."
          </h1>
          <p className='text-left text-xl'>Aristotle</p>
        </div>
        <div className='flex MainTBubble py-16 relative h-auto lg:h-[155vh] w-full flex-wrap gap-2'>
          <ProfileImage id={1} height={270} width={270} image={Kelvin} name="Kelvin Ramirez" designation="CEO" opacity={selectedId !== null ? (selectedId === 1 ? 1 : 0.2) : 1} />
          <ProfessionTxt id={1} onClick={() => handleProfessionTxtClick(1)} btSize="Size1" TLeft="160px" htext={50} ptext={20} TTop="0px" title="CEO" subtitle="" background="#1EA2E0" opacity={selectedId !== null ? (selectedId === 1 ? 1 : 0.2) : 1} />
          <ProfessionTxt id={2} onClick={() => handleProfessionTxtClick(2)} btSize="Size1" TLeft="160px" TTop="0px" htext={60} ptext={20} title="" subtitle="Founder" background="#1EA2E0" opacity={selectedId !== null ? (selectedId === 2 ? 1 : 0.2) : 1} />

          <ProfileImage id={2} height={270} ILeft="400px" ITop="0px" width={270} image={Shahzaib} name="Shahzaib Ramz" designation="Founder" opacity={selectedId !== null ? (selectedId === 2 ? 1 : 0.2) : 1} />
          <ProfessionTxt id={3} onClick={() => handleProfessionTxtClick(3)} btSize="Size1" TLeft="710px" TTop="" title="Social Media Managers" htext={18} subtitle="" background="#8165A6" opacity={selectedId !== null ? (selectedId === 3 ? 1 : 0.2) : 1} />
          <ProfileImage id={2} height={270} width={270} image={Umar} name="Umar Atiq" designation="Co-Founder" opacity={selectedId !== null ? (selectedId === 4 ? 1 : 0.2) : 1} />
          <ProfessionTxt id={4} onClick={() => handleProfessionTxtClick(4)} btSize="Size1" htext={20} ptext={20} title="Co-Founder" subtitle="" background="#8165A6" opacity={selectedId !== null ? (selectedId === 4 ? 1 : 0.2) : 1} />
          <ProfessionTxt id={5} onClick={() => handleProfessionTxtClick(5)} btSize="Size5" htext={60} ptext={30} title="" subtitle="Projects" background="#1EA2E0" opacity={selectedId !== null ? (selectedId === 5 ? 1 : 0.2) : 1} />
          <ProfessionTxt id={6} onClick={() => handleProfessionTxtClick(6)} btSize="Size4" htext={30} title="Manager" subtitle="" background="#1EA2E0" opacity={selectedId !== null ? (selectedId === 6 ? 1 : 0.2) : 1} />
          <ProfileImage id={2} height={180} width={180} image={Razziq} name="Raziq Mehdi" facebook="https://www.facebook.com/razziq.mehdi" designation="Web-Developer" opacity={selectedId !== null ? (selectedId === 7 ? 1 : 0.2) : 1} />
          <ProfileImage id={3} height={80} width={80} image={neo} designation="Neo.Pk"  opacity={selectedId !== null ? (selectedId === 5 ? 1 : 0.2) : 1} />
          <ProfileImage id={7} height={180} width={180} image={Saqeef} name="Saqeef Mamtaz" designation="Web-Developer" opacity={selectedId !== null ? (selectedId === 7 ? 1 : 0.2) : 1} />
          <ProfileImage id={6} height={150} width={150} image={Saqeef} name="Saqeef Mamtaz" designation="Web-Developer" opacity={selectedId !== null ? (selectedId === 7 ? 1 : 0.2) : 1} />
          <ProfileImage id={73} height={200} width={200} image={Moeed} name="Abdul Moeed" designation="Social Media Manager" opacity={selectedId !== null ? (selectedId === 3 ? 1 : 0.2) : 1} />
          <ProfessionTxt id={7} onClick={() => handleProfessionTxtClick(7)} btSize="Size1" htext={60} ptext={20} title="" subtitle="Developers" background="#1EA2E0" opacity={selectedId !== null ? (selectedId === 7 ? 1 : 0.2) : 1} />
          <ProfileImage id={5} height={300} width={300} image={waqas} name="M. Waqas" designation="Manager" opacity={selectedId !== null ? (selectedId === 6 ? 1 : 0.2) : 1} />
          <ProfileImage id={5} height={80} width={80} image={getinjury} designation="G-I-C" opacity={selectedId !== null ? (selectedId === 5 ? 1 : 0.2) : 1} />
          <ProfileImage id={6} height={90} width={90} image={fourcaps} designation="4Caps" opacity={selectedId !== null ? (selectedId === 5 ? 1 : 0.2) : 1} />
          <ProfileImage id={7} height={130} width={130} image={greenster} designation="Greenster" opacity={selectedId !== null ? (selectedId === 5 ? 1 : 0.2) : 1} />
          <ProfessionTxt id={8} onClick={() => handleProfessionTxtClick(8)} btSize="Size1" htext={25} ptext={25} title="motion" subtitle="designers" background="#1EA2E0" opacity={selectedId !== null ? (selectedId === 8 ? 1 : 0.2) : 1} />
        
        </div>
      </div>
    </div>
  );
};

export default Team;
