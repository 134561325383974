import React from "react";


const ImageSection = (props) => {
  const { imageUrl } = props;
  return (
    <div id="container">
      <div id="content-container">
        <div id="content">
          {/* Your content goes here */}
          <p style={{ textAlign: "center" }} className="lato">
            <h1 className="sfont">{props.heading1}</h1>
           {props.paragraph1}
          </p>
          <p style={{ textAlign: "center" }} className="lato">
            <h1 className="sfont">{props.heading2}</h1>
          {props.paragraph2}
          </p>
        </div>
        <div id="photo">
          {/* Your photo div goes here */}
          <img src={imageUrl} alt="Your" />
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
