import React from "react";
import HeroSection from "../components/HeroSection";
import Social from "../components/Social";
import Services from "../components/Services";
import Team from "../components/Team";
import StickyNavbar from "../components/StickyNavbar";
import Footer from "../components/Footer";
import Carousel from "../components/carousel";
// import Blog from "../components/Blog";

const HomePage = () => {
  return (
    <div id="hero">
      <HeroSection heading1="SHADE" heading2="OF" heading3="Success" label1="WORDS" label2="WORKS" label3="PROJECT" label4="TEAM" link1="#word" link2="#work" link3="#projects" link4="#team" link5="#findus"/>
      <Social
        heading1="Social"
        heading2="that"
        heading3="rocks"
        heading="10 YEARS OF CREATIVE HACKING"
        para1="4Caps Digital
        The key that refreshes the screen.
        What originally started out as a team of graphic designers, animators, and developers is today a team of thirty people whose roles range from strategy developers to brand content and UX Design. Whatever the format may be, their focus is to provide the best quality service for creating experiences and brand worlds that convey emotion, feeling, and that make communication a successful exchange with the final user.
        Our projects say more about us than long speeches do."
      />
      <Services />
  <Carousel />
      <Team />
      {/* <Blog /> */}
    
      <StickyNavbar menu1="Words" menu2="Works" menu3="Team" menu4="Find Us" menu5="Projects" link1="#word" link2="#work" link3="#projects" link4="#team" link5="#findus" />
      <Footer />
   
    </div>
  );
};

export default HomePage;
