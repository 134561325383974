import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage";
import WebService from "./Pages/WebServices";
import BrandingServices from "./Pages/BrandingServices";
import ArtificialIntelegence from "./Pages/ArtificialIntelegence";
import Blockchain from "./Pages/Blockchain";
import DigitalMarketing from "./Pages/DigitalMarketing";
import SocialMedia from "./Pages/SocialMedia";
import InfluencerMarketing from "./Pages/InfluencerMarketing";
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/webservice" element={<WebService />} />
          <Route
            path="/artificialintelegence"
            element={<ArtificialIntelegence />}
          />
          <Route path="/blockchain" element={<Blockchain />} />
          <Route path="/digitalmarketing" element={<DigitalMarketing />} />
          <Route path="/socialmedia" element={<SocialMedia />} />
          <Route
            path="/influencermarketing"
            element={<InfluencerMarketing />}
          />
          <Route path="/BrandingServices" element={<BrandingServices />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
